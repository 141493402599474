import React from 'react';

export default function LoaderIcon() {
  return (
    <div
      style={{
        position: 'fixed',
        top: 0,
        left: 0,
        width: '100%',
        height: '100%',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        backgroundColor: 'rgba(255, 255, 255, 0.5)',
        zIndex: 9999,
      }}
    >
      <div className="spinner-border" role="status">
        <span className="sr-only" />
      </div>
      <div>
        {' '}
        <h3 className="m-4">Upload in progress...</h3>
      </div>
    </div>
  );
}
