import * as yup from 'yup';

const createEmployerSchema = {
  name: yup
    .string()
    .required('Name is required.')
    .min(3, 'Names must be 3 to 32 alphabetic characters.')
    .max(32, 'Names must be 3 to 32 alphabetic characters.')
    .matches(
      /^(?!.*\s{2,})(?!.*\s$).*$/,
      'The input must not contain extra spaces; ensure there are no leading or trailing spaces and that there are no multiple spaces between words.'
    )
    .matches(/^[A-Za-z ]+$/, 'Name must contain only alphabets'),
  email: yup
    .string()
    .required('Email is required')
    .email('Enter a valid email.')
    .matches(
      /^(?!.*\.\.)(?!.*@{2,})(?!.*@.*@)(?!.*\.@)(?!@\.)(?!@-)(?!.*-$)[a-zA-Z0-9._%+-]+@[a-zA-Z.-]+\.[a-zA-Z]{1,}$/,
      'Enter a valid email.'
    )
    .matches(
      /\.[a-zA-Z]{2,62}$/,
      'Top-level domain (TLD) must be at least 2 characters and a maximum of 62 characters long.'
    ),
  password: yup
    .string()
    .required('Password is required.')
    .min(8, 'Input a password of at least 8 characters long.')
    .matches(
      /[a-z]/,
      'Password must contain at least one uppercase letter, one lowercase letter, one number, and one special character.'
    )
    .matches(
      /[A-Z]/,
      'Password must contain at least one uppercase letter, one lowercase letter, one number, and one special character.'
    )
    .matches(
      /[0-9]/,
      'Password must contain at least one uppercase letter, one lowercase letter, one number, and one special character.'
    )
    .matches(
      /[!@#$%^&*()_+{}]/,
      'Password must contain at least one uppercase letter, one lowercase letter, one number, and one special character.'
    ),
  companyName: yup
    .string()
    .required('Company Name is required')
    .min(3, 'Enter a company name between 3 and 100 characters in length.')
    .max(100, 'Enter a company name between 3 and 100 characters in length.')
    .matches(
      /^(?!.*\s{2,})(?!.*\s$).*$/,
      'The input must not contain extra spaces; ensure there are no leading or trailing spaces and that there are no multiple spaces between words.'
    ),
};

export default createEmployerSchema;
