import React, { useEffect, useState, useContext } from 'react';
import { useNavigate } from 'react-router-dom';
import { object } from 'yup';
import { useForm } from 'react-hook-form';
import { useDispatch } from 'react-redux';
import { toast } from 'react-toastify';
import { yupResolver } from '@hookform/resolvers/yup';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faArrowLeft } from '@fortawesome/fontawesome-free-solid';
import EmailInput from '../../components/EmailBox/EmailBox';
import TextInput from '../../components/TextField/TextField';
import { Entry } from '../../redux/entrySlice';
import PasswordField from '../../components/PasswordField/PasswordField';
import { TableContext } from '../../contexts/tableContext';
import styles from './createUser.module.scss';
import createEmployerSchema from './validations';

function UserCreate() {
  const formSchema = object().shape({
    name: createEmployerSchema.name,
    password: createEmployerSchema.password,
    companyName: createEmployerSchema.companyName,
    email: createEmployerSchema.email,
  });
  const { dashboardStyle } = useContext(TableContext);
  const [submiting, setSubmitting] = useState(false);
  const { bodyStyle } = useContext(TableContext);
  const { formthemeStyle } = useContext(TableContext);
  const { setDashboardHeader } = useContext(TableContext);
  const { errormsgStyle } = useContext(TableContext);
  const navigate = useNavigate();
  const [error, setError] = useState(null);
  useEffect(() => {
    window.scrollTo(0, 0);
    setDashboardHeader('Add Employer Details');
  }, []);
  const dispatch = useDispatch();
  const {
    register,
    handleSubmit,
    reset,
    formState: { errors },
  } = useForm({ resolver: yupResolver(formSchema) });
  function onSubmitdata(data) {
    setSubmitting(true);
    setError(null);
    const apiData = {
      name: data.name,
      email: data.email,
      companyName: data.companyName,
      password: data.password,
      url: window.location.href,
      isVerified: true,
    };
    data.actionUrl = 'user/employer/create';
    data.actionMethod = 'post';
    data.apiData = apiData;
    dispatch(Entry(data)).then((resp) => {
      setSubmitting(false);
      if (resp.payload.code === 200) {
        navigate(-1);
        toast.success('Employer creation successful.');
      } else if (resp.payload.code === 401 || resp.payload.code === 400) {
        setError(resp.payload.message);
      } else {
        setError('Something went wrong!');
      }
    });
  }
  return (
    <div className={styles[bodyStyle]}>
      <div
        className={styles[dashboardStyle]}
        style={{ minHeight: '100vh' }}
        id={styles.dashboardcont2}
      >
        <div className={styles[formthemeStyle]} id={styles.addform}>
          <FontAwesomeIcon
            icon={faArrowLeft}
            value="Back"
            onClick={() => {
              navigate(-1);
            }}
            className={styles.arrowback}
          />
          <div className="container-fluid mt-5">
            <form onSubmit={handleSubmit(onSubmitdata)}>
              <div className="row">
                <div className="col-lg-6 col-md-12 col-sm-12">
                  <TextInput
                    className={styles.inputbox}
                    classNamedark={styles.inputbox1}
                    labelstyle={styles.formlabel}
                    label="Name"
                    fieldName="name"
                    placeHolder="Enter Name"
                    register={register}
                    maximLength={32}
                    errors={errors}
                    isRequired
                    mandatory
                  />
                </div>
                <div className="col-lg-6 col-md-12 col-sm-12">
                  <EmailInput
                    className={styles.inputbox}
                    classNamedark={styles.inputbox1}
                    labelstyle={styles.formlabel}
                    label="Email"
                    placeHolder="Enter Email Address"
                    fieldName="email"
                    register={register}
                    errors={errors}
                    isRequired
                    mandatory
                  />
                </div>
              </div>
              <div className="row">
                <div className="col-lg-6 col-md-12 col-sm-12">
                  <TextInput
                    className={styles.inputbox}
                    classNamedark={styles.inputbox1}
                    labelstyle={styles.formlabel}
                    label="Company Name"
                    fieldName="companyName"
                    placeHolder="Enter Company Name"
                    register={register}
                    errors={errors}
                    maximLength={100}
                    isRequired
                    mandatory
                  />
                </div>
                <div className="col-lg-6 col-md-12 col-sm-12">
                  <PasswordField
                    fieldName="password"
                    className={styles.inputbox}
                    classNamedark={styles.inputbox1}
                    labelstyle={styles.formlabel}
                    label="Password"
                    register={register}
                    errors={errors}
                    placeHolder="Enter Password"
                    isRequired
                    minimLength={8}
                    eyeiconstyle={styles.eyeicon}
                    mandatory
                  />
                </div>
              </div>
              <div className="d-flex">
                <input
                  className={styles.formbtn}
                  type="submit"
                  disabled={submiting}
                  value={submiting ? 'Please wait..' : 'Submit'}
                />
                <input
                  className={styles.resetbtn}
                  type="button"
                  value="Reset"
                  onClick={() => {
                    reset();
                  }}
                />
              </div>
              <br />
              {error && (
                <h6 className={styles[errormsgStyle]} style={{ float: 'right' }}>
                  {error}
                </h6>
              )}
            </form>
          </div>
        </div>
      </div>
    </div>
  );
}

export default UserCreate;
